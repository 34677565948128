import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "../public/css/reset.css";
import "../public/js/rem";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
